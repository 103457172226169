<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
      @show-modal="clear(obj)"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseSearchCondition
            :searchCondition="searchCondition"
            :searchConditionInfo="searchConditionInfo"
            @clear="clear(obj)"
            @search="search(obj)"
          >
          </BaseSearchCondition>
          <br />
          <!-- テーブル -->
          <BasePagingTable
            v-model="searchCondition.size"
            id="work-list-table"
            select-mode="single"
            :fields="fields"
            :items.sync="tableItems"
            :selected-items.sync="selectedItem"
            :columnInfoPage="pagingInfo.page"
            :columnInfoSize="pagingInfo.size"
            :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
            :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
            :perPage="perPage"
            @size-changed="updateSize"
          />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 選択ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.select"
          :disabled="!oneSelected"
          @click="select"
        />
        <!-- 選択解除ボタン -->
        <BaseButton
          v-if="useUnset"
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.unselect"
          @click="unselect"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      :id="makeSubModalId"
      type="entry"
      :displayModals="displayModals"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal-edit"
      type="entry"
      displayModals="two"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 実車番マスタモーダル -->
    <RealVehicleModal
      id="real-vehicle-modal-edit"
      type="entry"
      displayModals="two"
      :selectedSearchCondition="realVehicleModalSearchCondition"
      @after-close-set="afterCloseSetRealVehicle"
      @after-close-unset="afterCloseUnsetRealVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
  openSubModal,
} from '@/common/Common.js'
import { search, clear } from '@/master/passagePermit/PassagePermitModal.js'
import {
  DELETED_KIND,
  DEPARTMENT_KIND,
  REAL_VEHICLE_KIND_SEARCH,
} from '@/common/const.js'

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    RealVehicleModal: () => import('@/master/realVehicle/RealVehicleModal.vue'),
  },
  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
      placeModalKbn: -1,
      realVehicleModalKbn: -1,

      departmentModalSearchCondition: {},
      realVehicleModalSearchCondition: {},
    }
  },

  props: {
    useUnset: {
      type: Boolean,
      default: true,
    },
    selectedSearchCondition: {
      type: Object,
      default: () => {},
    },
    // [department]
    // 'one'  ->'department-modal'
    // 'two'  ->'department-modal-edit'
    // 'three'->'department-modal-three'
    // 'four' ->'department-modal-four'
    displayModals: {
      type: String,
      default: 'one',
    },
  },
  watch: {
    selectedSearchCondition() {
      this.searchCondition.permitVehicleIds = this.selectedSearchCondition.permitVehicleIds

      //[#2689]
      this.searchCondition.carrierCode = this.selectedSearchCondition.carrierCode
      this.searchCondition.carrierName = this.selectedSearchCondition.carrierName
      this.searchCondition.realVehicleId = this.selectedSearchCondition.realVehicleId
      this.searchCondition.realVehicleNumberplate = this.selectedSearchCondition.realVehicleNumberplate
      this.searchCondition.realTrailerId = this.selectedSearchCondition.realTrailerId
      this.searchCondition.realTrailerNumberplate = this.selectedSearchCondition.realTrailerNumberplate

      this.searchCondition.departurePlaceCode = this.selectedSearchCondition.departurePlaceCode
      this.searchCondition.departurePlaceName = this.selectedSearchCondition.departurePlaceName
      this.searchCondition.arrivalPlaceCode = this.selectedSearchCondition.arrivalPlaceCode
      this.searchCondition.arrivalPlaceName = this.selectedSearchCondition.arrivalPlaceName
    },
  },
  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PASSAGE_PERMIT_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {
                departmentKind: DEPARTMENT_KIND.CARRIER,
              }
              //this.openSubModal(this, 'department-modal-edit')
              this.openSubModal(this, this.makeSubModalId)
            },
          },
          // 実車両(TR)
          {
            // トラック/トラクタ (TR)
            type: 'referenceCodeName',
            codeId: 'realVehicleId',
            nameId: 'realVehicleNumberplate',
            codeColumnInfo: searchConditionInfo.real_vehicle_id,
            nameColumnInfo: searchConditionInfo.real_vehicle_numberplate,
            btnColumnInfo: searchConditionInfo.sub_modal_real_vehicle,
            codeMaxLength: 15,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            onClickCallback: () => {
              this.realVehicleModalKbn = 1
              this.realVehicleModalSearchCondition = {}
              this.realVehicleModalSearchCondition.realVehicleKind =
                REAL_VEHICLE_KIND_SEARCH.TRTC
              this.openSubModal(this, 'real-vehicle-modal-edit')
            },
          },
          // 実車両(TL)
          {
            // トレーラー (TL)
            type: 'referenceCodeName',
            codeId: 'realTrailerId',
            nameId: 'realTrailerNumberplate',
            codeColumnInfo: searchConditionInfo.real_trailer_id,
            nameColumnInfo: searchConditionInfo.real_trailer_numberplate,
            btnColumnInfo: searchConditionInfo.sub_modal_real_trailer,
            codeMaxLength: 15,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            onClickCallback: () => {
              this.realVehicleModalKbn = 2
              this.realVehicleModalSearchCondition = {}
              this.realVehicleModalSearchCondition.realVehicleKind =
                REAL_VEHICLE_KIND_SEARCH.TL
              this.openSubModal(this, 'real-vehicle-modal-edit')
            },
          },
          {
            // 出荷場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal-edit')
            },
          },
          {
            // 搬入場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 2
              this.openSubModal(this, 'place-modal-edit')
            },
          },
          {
            // 許可番号
            type: 'text',
            id: 'permitNo',
            columnInfo: searchConditionInfo.permit_no,
            maxLength: 50,
          },
          {
            // 有効開始日付
            type: 'date',
            id: 'validBeginDate',
            columnInfo: searchConditionInfo.valid_begin_date,
            maxLength: 14,
          },
          {
            // 許可証条件
            type: 'select',
            id: 'permitCondition',
            columnInfo: searchConditionInfo.permit_condition,
            options: getListOptions(this.MASTER_CODE.PERMIT_CONDITION),
            onChangeCallback: () => {},
          },
          {
            // 通行区分(往復区分)
            type: 'select',
            id: 'onewayOrRound',
            columnInfo: searchConditionInfo.oneway_or_round,
            options: getListOptions(this.MASTER_CODE.ONEWAY_OR_ROUND),
            onChangeCallback: () => {},
          },
          {
            // 高速使用有無(高速利用可)
            type: 'select',
            id: 'useHighway',
            columnInfo: searchConditionInfo.use_highway,
            options: getListOptions(this.MASTER_CODE.HIGHWAY_USE_YN_FLAG),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PASSAGE_PERMIT_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PASSAGE_PERMIT_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },

    /**
     * 部署参照モーダルID作成
     */
    makeSubModalId() {
      //開かれたdisplayModalsによりサブモーダルIDも変わるように修正
      let newId = 'department-modal'
      if (this.displayModals == 'two') {
        newId = 'department-modal-edit'
      } else if (this.displayModals == 'three') {
        newId = 'department-modal-three'
      } else if (this.displayModals == 'four') {
        newId = 'department-modal-four'
      }
      return newId
    },
  },

  methods: {
    getListOptions,
    search,
    clear,
    openSubModal,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let permitVehicleIds = null
      if (this.selectedSearchCondition.permitVehicleIds != null) {
        permitVehicleIds = this.selectedSearchCondition.permitVehicleIds
      }
      return {
        carrierId: null,
        carrierCode: null,
        carrierName: null,
        realVehicleId: null,
        realVehicleNumberplate: null,
        realTrailerId: null,
        realTrailerNumberplate: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        permitNo: null,
        validBeginDate: null,
        permitCondition: null,
        onewayOrRound: null,
        useHighway: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.PASSAGE_PERMIT_MODAL_LIST,
        domainName: 'result_fields',
        isModalSearch: '1',
        permitVehicleIds: permitVehicleIds,
      }
    },

    select() {
      this.$bvModal.hide('passage-permit-modal')
      this.$emit(
        'after-close-set',
        this.selectedItem[0]
        //this.selectedItem[0].passage_permit_id,
        //this.selectedItem[0].permit_no
        //this.selectedItem[0].vehicle_model,
        //this.selectedItem[0].vehicle_model_name,
        //this.selectedItem[0].
      )
    },

    unselect() {
      this.$bvModal.hide('passage-permit-modal')
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierId = id
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierId = null
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },
    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetRealVehicle(selectedItem) {
      // ※[id + ''] -> 検索条件で使用する。
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.searchCondition.realVehicleId = selectedItem.real_vehicle_id + ''
        this.searchCondition.realVehicleNumberplate = selectedItem.numberplate
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.searchCondition.realTrailerId = selectedItem.real_vehicle_id + ''
        this.searchCondition.realTrailerNumberplate = selectedItem.numberplate
      }
    },
    /**
     * 実車番マスタ情報解除
     */
    afterCloseUnsetRealVehicle() {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.searchCondition.realVehicleId = null
        this.searchCondition.realVehicleNumberplate = null
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.searchCondition.realTrailerId = null
        this.searchCondition.realTrailerNumberplate = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
