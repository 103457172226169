import { openMsgModal, displaYyyymmdd, displayHHmmss } from '@/common/Common.js'
import PassagePermitService from './PassagePermitService'
import { MSG_TYPE } from '@/common/const.js'

export function search(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  PassagePermitService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        obj.tableItems.forEach((element) => {
          element.valid_begin_date = displaYyyymmdd(
            obj,
            element.valid_begin_date
          )
          element.valid_end_date = displaYyyymmdd(obj, element.valid_end_date)
          element.permit_time_from = displayHHmmss(element.permit_time_from)
          element.permit_time_to = displayHHmmss(element.permit_time_to)
        })

        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  obj.tableItems = []
}
